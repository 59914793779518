import React, { useEffect, useState } from 'react'
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa'
import { FaDownload } from 'react-icons/fa6'

import {
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'

import FormTab from './parts/Tabs/FormTab'
import { InputDisabledProps } from './types'

const InputDisabled = ({ label, placeholder }: InputDisabledProps) => (
  <Box w="50%">
    <FormLabel fontSize="sm" opacity={0.5}>
      {label}
    </FormLabel>
    <Input
      placeholder={placeholder}
      disabled
      bg="brand.neutral.light_2"
      borderColor="brand.neutral.light_2"
      _placeholder={{ fontStyle: 'italic' }}
    />
  </Box>
)

const FormAnswersVolume = () => {
  const tabs: ITabItem[] = [
    {
      id: 'form',
      label: 'Formulário',
      render: () => <FormTab />,
    },
  ]

  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined
    if (isRunning) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isRunning])

  const handleStopwatch = () => {
    setIsRunning(prevState => !prevState)
  }

  const handleReset = () => {
    setIsRunning(false)
    setTime(0)
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`
  }

  const RenderInfoVolume = () => (
    <Flex
      direction="column"
      gap="4"
      py="5"
      px="5"
      boxShadow="md"
      borderRadius="md"
    >
      <Flex justify="space-between">
        <Text
          color="brand.primary.dark_1"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          ID Volume
        </Text>
        <Tag group="statusObjects" value="active" />
      </Flex>
      <Box w="full" h="full" gap="4">
        <Flex direction="row" gap="4">
          <InputDisabled label="Edital" placeholder="Edital" />
          <InputDisabled label="Editora" placeholder="Editora Ltda" />
          <InputDisabled label="Coleção" placeholder="Coleção" />
        </Flex>

        <Flex direction="row" gap="4" mt="5">
          <InputDisabled
            label="Formato do volume"
            placeholder="Formato do volume"
          />
          <InputDisabled
            label="Versão do volume"
            placeholder="Versão do volume"
          />
          <InputDisabled
            label="Exemplar do volume"
            placeholder="Exemplar do volume"
          />
        </Flex>

        <Flex align="center" justify="space-between" mt="5">
          <Button leftIcon={<FaDownload />} variant="solid">
            Baixe volume do PNLD digital
          </Button>
          <Flex align="center" minW={100}>
            <IconButton
              variant="ghost"
              aria-label="Play"
              icon={
                !isRunning ? (
                  <FaPlayCircle fontSize={36} />
                ) : (
                  <FaPauseCircle fontSize={36} />
                )
              }
              mr="2"
              onClick={handleStopwatch}
            />
            <Text color="brand.neutral.dark_1" fontSize="md">
              {formatTime(time)}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )

  const RenderFormAnswers = () => {
    const [activeTabId, setActiveTabId] = useState('form')

    return (
      <Flex
        direction="column"
        gap="4"
        py="5"
        px="5"
        boxShadow="md"
        borderRadius="md"
      >
        <Tabs
          items={tabs}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="6">
      <RenderInfoVolume />
      <RenderFormAnswers />
    </Flex>
  )
}

export default FormAnswersVolume
