import React from 'react'
import { FaClipboardList } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, IconButton, Text, VStack } from '@chakra-ui/react'

import Tag from '@/components/Tag'

export const DataTableVolumes = () => {
  const { coObject } = useParams()

  const navigate = useNavigate()

  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      volumeCode: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              AL LE 000 000 0000 PP00 00 00 000 000
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Editora | Coleção | Versão
            </Text>
          </Box>
        </VStack>
      ),
      exemplar: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Formato | Duração/páginas | Média de tempo
            </Text>
          </Box>
        </VStack>
      ),
      status: <Tag group="statusAnalysisVolume" value={'awaitingApproval'} />,
      viewVolume: (
        <IconButton
          aria-label="Ver detalhes do objeto"
          variant="ghost"
          size="sm"
          icon={<FaClipboardList size="20px" />}
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(
              `../../objetos/detalhes-objeto-volumes/${coObject}/formulario-volume/1`
            )
          }
        />
      ),
    })
  }

  return data
}
