import React from 'react'

import { Tag } from '@chakra-ui/react'

import {
  IStatusAnalysisVolumeProps,
  IStatusAnalysisVolumeTagStyles,
} from './types'

const styles: IStatusAnalysisVolumeTagStyles = {
  waitingReanalysis: {
    name: 'Solicitação de reanálise',
    backgroundColor: '#FFF3F2',
    color: '#E60000',
  },
  awaitingApproval: {
    name: 'Aguardando aprovação',
    backgroundColor: '#FFF3F2',
    color: '#DDA253',
  },
}

function StatusAnalysisVolume({ tag, ...rest }: IStatusAnalysisVolumeProps) {
  const { name, backgroundColor, color } =
    styles[tag] || styles.waitingReanalysis

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusAnalysisVolume
