import React, { useState } from 'react'
import { FaSync } from 'react-icons/fa'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'

import OpenReanalysisVolumesModal from './parts/OpenReanalysisVolumesModal'
import TableVolumesDetailsObject from './TableVolumesDetailsObject'
import { DataTableVolumes } from './TableVolumesDetailsObject/DataTable'

const DetailsObjectVolumes = () => {
  const isLoading = false

  // TODO: RQ-12 Waiting for api to implement validation
  const isProfileCoordinator = true

  const {
    isOpen: isConfirmReanalysisVolumesOpen,
    onOpen: onConfirmReanalysisVolumesOpen,
    onClose: onConfirmReanalysisVolumesClose,
  } = useDisclosure()

  const handleOpenReanalysisVolumesModal = () => {
    onConfirmReanalysisVolumesOpen()
  }

  const tabsDefault: ITabItem[] = [
    {
      id: 'pending',
      label: 'Pendentes',
      render: () => <TableVolumesDetailsObject details={DataTableVolumes()} />,
    },
    {
      id: 'finished',
      label: 'Finalizados',
      render: () => <TableVolumesDetailsObject details={DataTableVolumes()} />,
    },
  ]

  const tabsCoordinator: ITabItem[] = [
    {
      id: 'pending',
      label: 'Pendentes',
      render: () => <TableVolumesDetailsObject details={DataTableVolumes()} />,
    },
    {
      id: 'inProgress',
      label: 'Em andamento',
      render: () => <TableVolumesDetailsObject details={DataTableVolumes()} />,
    },
    {
      id: 'finished',
      label: 'Finalizados',
      render: () => <TableVolumesDetailsObject details={DataTableVolumes()} />,
    },
  ]

  const ObjectInfo = () => {
    return (
      <>
        <OpenReanalysisVolumesModal
          isOpen={isConfirmReanalysisVolumesOpen}
          onClose={onConfirmReanalysisVolumesClose}
        />

        <Flex
          direction="column"
          gap="4"
          py="5"
          px="5"
          boxShadow="md"
          borderRadius="md"
        >
          <Flex justify="space-between">
            <Text
              color="#475671"
              fontSize="20px"
              fontWeight="700"
              lineHeight="120%"
            >
              Objeto 01: Obras Didáticas - Impresso e Digital-Interativo
            </Text>
            <Tag group="statusObjects" value="ongoing" />
          </Flex>

          <Box w="full" h="full" gap="4">
            <Flex direction="row" gap="4">
              <Box w="50%">
                <FormLabel fontSize="sm">Edital</FormLabel>
                <Input
                  placeholder="Edital"
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
              <Box w="50%">
                <FormLabel fontSize="sm">Código do Objeto</FormLabel>
                <Input
                  placeholder="0172 P22 02 02 000 000"
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
              <Box w="50%">
                <FormLabel fontSize="sm">Fase</FormLabel>
                <Input
                  placeholder="Análise de insumos"
                  disabled
                  bg="brand.neutral.light_2"
                  borderColor="brand.neutral.light_2"
                  _placeholder={{ fontStyle: 'italic' }}
                />
              </Box>
            </Flex>
            {isProfileCoordinator && (
              <Flex mt={5} justifyContent="flex-end">
                <Button
                  leftIcon={<FaSync />}
                  variant="solid"
                  onClick={handleOpenReanalysisVolumesModal}
                >
                  Abrir reanálise dos volumes
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      </>
    )
  }

  const Volumes = () => {
    const [activeTabId, setActiveTabId] = useState('pending')

    if (isLoading) {
      return (
        <Flex
          direction="column"
          gap="4"
          p="5"
          boxShadow="md"
          borderRadius="md"
          h="60vh"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor="white"
            flex={1}
            w="100%"
          >
            <VStack spacing={4}>
              <Text>Buscando dados dos objeto...</Text>
              <CircularProgress
                size="5rem"
                thickness="0.25rem"
                isIndeterminate
              />
            </VStack>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex direction="column" gap="4" p="5" boxShadow="md" borderRadius="md">
        <Tabs
          items={isProfileCoordinator ? tabsCoordinator : tabsDefault}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="6">
      <ObjectInfo />
      <Volumes />
    </Flex>
  )
}

export default DetailsObjectVolumes
