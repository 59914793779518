import React from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaCheckCircle, FaSave } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Form, Formik } from 'formik'

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import Select from '@/components/Select'

import ConfirmFormVolumeModal from '../../ConfirmFormVolumeModal'

const FormTab = () => {
  const navigate = useNavigate()
  const { coObject } = useParams()

  const {
    isOpen: isConfirmFormVolumeOpen,
    onOpen: onConfirmFormVolumeOpen,
    onClose: onConfirmFormVolumeClose,
  } = useDisclosure()

  const handleOpenConfirmFormVolumeModal = () => {
    onConfirmFormVolumeOpen()
  }

  const optionsRadio = [
    { value: 'a', label: 'Opção A' },
    { value: 'b', label: 'Opção B' },
    { value: 'c', label: 'Opção C' },
    { value: 'd', label: 'Opção D' },
    { value: 'e', label: 'Opção E' },
    { value: 'f', label: 'Opção F' },
  ]

  const optionsRadioBoolean = [
    {
      value: 'true',
      label: 'Sim',
    },
    {
      value: 'false',
      label: 'Não',
    },
  ]

  const optionsSelect = [
    {
      value: '1',
      label: 'Página 1',
    },
    {
      value: '2',
      label: 'Página 2',
    },
    {
      value: '3',
      label: 'Página 3',
    },
    {
      value: '4',
      label: 'Página 4',
    },
    {
      value: '5',
      label: 'Página 5',
    },
  ]

  return (
    <Stack>
      <ConfirmFormVolumeModal
        isOpen={isConfirmFormVolumeOpen}
        onClose={onConfirmFormVolumeClose}
      />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <Flex direction="row" justify="space-between" mt={4}>
            <RadioGroup name="scope" size="lg" mb={8} minW="80%" maxW="80%">
              <Text fontSize="md" fontWeight="bold" mb={4}>
                1. Where does it come from?
              </Text>
              {optionsRadio.map(option => (
                <Radio
                  key={`scope-radio-${option.value}`}
                  value={option.value}
                  // onChange={() => }
                  _checked={{
                    bg: 'brand.primary.base',
                  }}
                  mr={20}
                >
                  <Text fontSize="sm">{option.label}</Text>
                </Radio>
              ))}
            </RadioGroup>
            <Box w={240}>
              <FormLabel fontSize="sm" fontWeight="semibold">
                Em quais páginas isso aparece?
              </FormLabel>
              <Select
                name="numberPage1"
                placeholder="--"
                options={optionsSelect}
                isMulti
                maxMenuHeight={130}
              />
            </Box>
          </Flex>

          <Flex direction="row" justify="space-between" mt={4}>
            <RadioGroup name="scope" size="lg" mb={8} minW="80%" maxW="80%">
              <Text fontSize="md" fontWeight="bold" mb={4}>
                2. Lorem Ipsumis simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industrys
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsu?
              </Text>
              {optionsRadio.map(option => (
                <Radio
                  key={`scope-radio-${option.value}`}
                  value={option.value}
                  // onChange={() => }
                  _checked={{
                    bg: 'brand.primary.base',
                  }}
                  mr={20}
                >
                  <Text fontSize="sm">{option.label}</Text>
                </Radio>
              ))}
            </RadioGroup>
            <Box w={240}>
              <FormLabel fontSize="sm" fontWeight="semibold">
                Em quais páginas isso aparece?
              </FormLabel>
              <Select
                name="numberPage2"
                placeholder="--"
                options={optionsSelect}
                isMulti
                maxMenuHeight={130}
              />
            </Box>
          </Flex>

          <Flex direction="row" justify="space-between" mt={4}>
            <RadioGroup name="scope" size="lg" mb={8} minW="80%" maxW="80%">
              <Text fontSize="md" fontWeight="bold" mb={4}>
                3. Etiam eget dignissim tortor, eu rhoncus purus. Nunc vitae
                justo eget mauris sodales laoreet sed ac odio. Donec nec mauris
                enim. Morbi at rutrum leo. Maecenas ipsum tortor, tempor quis
                malesuada quis, efficitur at ligula. Nunc efficitur venenatis
                nunc eu tempor. Aliquam placerat auctor nibh, ut blandit orci
                ultricies eu?
              </Text>
              {optionsRadioBoolean.map(option => (
                <Radio
                  key={`scope-radio-${option.value}`}
                  value={option.value}
                  // onChange={() => }
                  _checked={{
                    bg: 'brand.primary.base',
                  }}
                  mr={20}
                >
                  <Text fontSize="sm">{option.label}</Text>
                </Radio>
              ))}
            </RadioGroup>
            <Box w={240}>
              <FormLabel fontSize="sm" fontWeight="semibold">
                Em quais páginas isso aparece?
              </FormLabel>
              <Select
                name="numberPage3"
                placeholder="--"
                options={optionsSelect}
                isMulti
                maxMenuHeight={130}
              />
            </Box>
          </Flex>
        </Form>
      </Formik>
      <Flex justify="space-between">
        <Button
          size="sm"
          leftIcon={<BsChevronLeft />}
          variant="ghost"
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(`/objetos/detalhes-objeto-volumes/${coObject}`)
          }
        >
          Voltar
        </Button>
        <Flex gap={2}>
          <Button leftIcon={<FaSave />} minW={200}>
            Salvar edição
          </Button>
          <Button
            leftIcon={<FaCheckCircle />}
            backgroundColor="brand.success.base"
            minW={200}
            _hover={{
              bg: 'brand.success.dark',
            }}
            onClick={handleOpenConfirmFormVolumeModal}
          >
            Enviar formulário
          </Button>
        </Flex>
      </Flex>
    </Stack>
  )
}

export default FormTab
