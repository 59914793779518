import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusUserTeamTagProps, IStatusUserTeamTagStyles } from './types'

const styles: IStatusUserTeamTagStyles = {
  active: {
    name: 'Ativo',
    backgroundColor: 'brand.success.light',
    color: 'brand.success.base',
  },
  finished: {
    name: 'Finalizado',
    backgroundColor: 'brand.primary.light_2',
    color: 'brand.primary.dark_1',
  },
  divergent: {
    name: 'Divergente',
    backgroundColor: 'brand.error.light',
    color: 'brand.error.base',
  },
  reanalysis: {
    name: 'Reanálise',
    backgroundColor: 'brand.error.light',
    color: 'brand.error.base',
  },
  default: {
    name: 'default',
    backgroundColor: 'gray.500',
  },
}

function StatusTag({ tag, ...rest }: IStatusUserTeamTagProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
