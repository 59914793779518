import React from 'react'
import { useMemo, useState } from 'react'

import { Box } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

interface IOverviewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: any[]
}

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
  {
    name: '',
    key: 'viewVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

export const tableHeadersCoordinator: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Estado',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: '',
    key: 'viewVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

export const statusFilter: IFilter = {
  name: 'status',
  label: 'Status',
  type: 'select',
  options: [
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
    { label: 'Finalizado', value: 'finished' },
    { label: 'Em andamento', value: 'ongoing' },
    { label: 'Aguardando vínculo', value: 'waiting_bond' },
    { label: 'Fase Externa', value: 'external_phase' },
    { label: 'Não iniciado', value: 'not_started' },
  ],
}

const TableVolumesDetailsObject: React.FC<IOverviewProps> = ({ details }) => {
  // TODO: RQ-12 Waiting for api to implement validation
  const isProfileCoordinator = true

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: details.length,
    pageCount: Math.ceil(details.length / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(details.length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = details.slice(start, end)

    return paginatedData.map(item => {
      return {
        ...item,
      }
    })
  }, [details, pagination])

  return (
    <Box w="full">
      <Box py={4}>
        <DataFilter
          testId="data-filter"
          canDownload={false}
          onlySearch
          filters={[statusFilter]}
          onChangeFilter={() => {}}
        />
      </Box>
      <Box>
        <DataTable
          key="table-details-modal"
          data={mapTableData}
          headers={
            isProfileCoordinator ? tableHeadersCoordinator : tableHeaders
          }
          selectableRow={false}
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Box>
    </Box>
  )
}

export default TableVolumesDetailsObject
