import React from 'react'

import { Flex } from '@chakra-ui/react'

import ManagementObjects from '@/modules/objects/ManagementObjects'

import ManagementDetailsObjects from './ManagementDetailsObjects'

const Objects = () => {
  const roleProfile = 'analyst'

  const renderObjects = () => {
    if (roleProfile === 'analyst') {
      return <ManagementObjects />
    }

    return <ManagementDetailsObjects />
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      {renderObjects()}
    </Flex>
  )
}

export default Objects
