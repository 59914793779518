import React, { useMemo, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Button, Flex } from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import { Input, Select } from '@/components/Forms'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

import { DataTableReanalysisModal } from './DataTables'
import S from './styles'
import {
  IOpenReanalysisVolumesModalProps,
  TOpenReanalysisVolumesModalFormValues,
} from './types'

const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
]

const optionsSelect = [
  {
    value: '1',
    label: 'Analista 1',
  },
  {
    value: '2',
    label: 'Analista 2',
  },
  {
    value: '3',
    label: 'Analista 3',
  },
]

const OpenReanalysisVolumesModal: React.FC<
  IOpenReanalysisVolumesModalProps
> = ({ isOpen, onClose }) => {
  const [selectedRows, setSelectedRows] = useState<Array<RowData>>([])

  const handleConfirmFormVolume = () => {
    onClose()
  }

  const validationSchema = yup.object({
    analyst: yup.string().required('O analista é obrigatório'),
    limitDate: yup.string().required('A data é obrigatória'),
  })

  const onSubmit = (values: TOpenReanalysisVolumesModalFormValues) => {
    const payload = {
      analyst: values.analyst,
      limitDate: values.limitDate,
    }
  }

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    itemsCount: DataTableReanalysisModal().length,
    pageCount: Math.ceil(DataTableReanalysisModal().length / 10),
    pageSizeOptions: [5, 10, 15, 20, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(DataTableReanalysisModal().length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = DataTableReanalysisModal().slice(start, end)

    return paginatedData.map(item => {
      return {
        ...item,
      }
    })
  }, [DataTableReanalysisModal, pagination])

  return (
    <Modal
      title="Deseja abrir reanálise dos volumes?"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: '5xl',
      }}
    >
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          analyst: '',
          limitDate: '',
        }}
      >
        <Form>
          <Flex gap={8} mb={8}>
            <Select
              name="analyst"
              label="Quem irá fazer a reanálise dos volumes selecionados?"
              placeholder="Selecione o analista"
              options={optionsSelect}
            />
            <Input
              label="Tempo limite de reanálise"
              name="limitDate"
              placeholder="---"
            />
          </Flex>
          <DataTable
            key="table-reanalysis-modal"
            rowId="id"
            data={mapTableData}
            headers={tableHeaders}
            onRowSelectionChange={rows => setSelectedRows(rows)}
            selectableRow
          />
          <Pagination
            pageIndex={pagination.page - 1}
            optionsItemsPerPage={pagination.pageSizeOptions}
            itemsPerPage={pagination.pageSize}
            pageCount={pagination.pageCount}
            itemsCount={pagination.itemsCount}
            onChangePageInfo={handleOnChangePageInfo}
          />
          <S.ModalActionButtons>
            <Button
              key="cancel"
              variant="outline"
              size="sm"
              borderRadius={50}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              key="confirm"
              size="sm"
              leftIcon={<FaCheckCircle />}
              onClick={handleConfirmFormVolume}
              borderRadius={50}
            >
              Abrir reanálise
            </Button>
          </S.ModalActionButtons>
        </Form>
      </Formik>
    </Modal>
  )
}

export default OpenReanalysisVolumesModal
