import React, { useState } from 'react'
import { FaEye } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import { Box, IconButton, Text, VStack } from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

const TableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volume',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplary',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Fase',
    key: 'phase',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: '',
    key: 'viewDetailsVolume',
    type: 'element',
    width: '5%',
    align: 'center',
  },
]

const DataTableManagementVolumes = () => {
  const roleProfile = 'supervisor'

  const navigate = useNavigate()

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 20,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  const data = []

  for (let i = 0; i < 10; i++) {
    data.push({
      volume: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              AL LE 000 000 0000 PP00 00 00 000 000
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Editora | Coleção | Versão
            </Text>
          </Box>
        </VStack>
      ),
      exemplary: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              PDF | 300 pg | 30 min/av
            </Text>
          </Box>
        </VStack>
      ),
      phase: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Validação de inscrição
            </Text>
          </Box>
        </VStack>
      ),
      status: <Tag group="statusVolumes" value="active" />,
      viewDetailsVolume: (
        <IconButton
          aria-label="Ver detalhes do volume"
          variant="ghost"
          size="sm"
          icon={<FaEye size="20px" />}
          color="brand.primary.dark_1"
          onClick={() =>
            navigate(
              '../../objetos/detalhes-objeto-volumes/1/formulario-volume/1'
            )
          }
        />
      ),
    })
  }

  return (
    <>
      <DataTable headers={TableHeaders} data={data} />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </>
  )
}

export default DataTableManagementVolumes
