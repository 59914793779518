import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import S from './styles'
import { IConfirmFormVolumeModalProps } from './types'

const ConfirmFormVolumeModal: React.FC<IConfirmFormVolumeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()
  const { coObject } = useParams()

  const handleConfirmFormVolume = () => {
    navigate(`/objetos/detalhes-objeto-volumes/${coObject}`)
    onClose()
  }

  return (
    <Modal
      title="Deseja enviar as suas respostas do formulário vinculadas ao volume? Caso
        as respostas sejam enviadas, não poderão ser alteradas."
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        md: '3xl',
      }}
    >
      <S.ModalActionButtons>
        <Button
          key="cancel"
          variant="outline"
          size="sm"
          borderRadius={50}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          key="confirm"
          size="sm"
          leftIcon={<FaCheckCircle />}
          onClick={handleConfirmFormVolume}
          borderRadius={50}
        >
          Sim, desejo enviar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default ConfirmFormVolumeModal
