import React from 'react'

import { Box, Text, VStack } from '@chakra-ui/react'

export const DataTableReanalysisModal = () => {
  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      id: i + 1,
      volumeCode: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              AL LE 000 000 0000 PP00 00 00 000 000
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Editora | Coleção | Versão
            </Text>
          </Box>
        </VStack>
      ),
      exemplar: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Livro do aluno
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              Formato | Duração/páginas | Média de tempo
            </Text>
          </Box>
        </VStack>
      ),
    })
  }

  return data
}
