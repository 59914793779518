import React, { useMemo, useState } from 'react'

import { SearchIcon } from '@chakra-ui/icons'
import { Box, CircularProgress, Flex, Text, VStack } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import { reverseDate } from '@/utils/date'

import ExmptyObjectsPanel from '../../../pages/Panel/Objects/EmptyObjects'
import DataTableManagementObjects from './DataTable'

const ManagementObjects = () => {
  const objects = true
  const isLoading = false

  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    stage: '',
  })

  const filterOptions: Array<IFilter> = useMemo(
    () => [
      {
        name: 'stage',
        label: 'Fase',
        type: 'text',
        icon: <SearchIcon />,
      },
    ],
    []
  )

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      stage: filters.stage || '',
    })
  }

  const renderObjects = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
        >
          <VStack spacing={4}>
            <Text>Buscando dados dos objetos...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!objects) {
      return <ExmptyObjectsPanel />
    }

    return (
      <Flex direction="column" flex={1}>
        <Box overflowX="auto" flex={1} py={4}>
          <DataTableManagementObjects />
        </Box>
      </Flex>
    )
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        boxShadow="md"
        p={4}
      >
        <DataFilter
          id="data-filter"
          filters={filterOptions}
          onChangeFilter={handleFilters}
        />
      </Flex>
      <Flex
        borderRadius="md"
        boxShadow="md"
        direction="column"
        flex={1}
        w="100%"
        p={5}
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            'th, td': { whiteSpace: 'nowrap' },
          },
        }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          sx={{
            '@media (max-width: 500px)': {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              'th, td': { whiteSpace: 'nowrap' },
            },
          }}
        >
          <Text
            fontSize="xl"
            color="brand.primary.dark_1"
            fontWeight="semibold"
          >
            Gestão de Objetos
          </Text>
        </Flex>
        {renderObjects()}
      </Flex>
    </Flex>
  )
}

export default ManagementObjects
